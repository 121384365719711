import * as React from "react"
import { Helmet } from "react-helmet"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import ContactForm from "../components/common/contact-form/contact-form"
import Layout from "../components/layout/Layout"
import Heading from "../components/common/heading/Heading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faPencilRuler, faCommentDollar } from "@fortawesome/free-solid-svg-icons"
import { OurServices } from "../components/services/OurServices"

const ServiciosPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Mahpsa | Servicios</title>
      </Helmet>
      <Breadcrumb title={"Servicios"} />
      <div className="section-wrapper pt-0">
        <OurServices/>
      </div>
    </Layout>
  )
}

export default ServiciosPage
